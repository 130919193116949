<template>
  <v-container fluid class="pa-0">
    <v-sheet>
      <common-error
        v-if="isErrorGetList"
        @fetchData="isErrorGetList = false"
      />
      <section v-else>
        <FilterList
          :filters="filters"
          @fetchOrders="fetchOrders"
        />
        <v-data-table
          item-key="index"
          calculate-widths
          :loading="isLoading"
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          @click:row="rowClicked"
        >
          <template v-slot:[`item.longitude`]={item}>
            <span>{{ item.longitude }} {{ item.latitude }}</span>
            <span class="text-caption d-block grey--text text--darken-2">{{perseRelativeTime(item.updatedAt)}}</span>
          </template>
          <template v-slot:[`item.latestStatus`]="{item}">
            <v-btn
              :color="shipmentColorStatus(item.latestStatus)"
              class="white--text w-100"
              small
            >
              {{item.latestStatus}}
            </v-btn>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              {{$_strings.order.PAGE_NAME}}
              <span v-if="items.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </section>
    </v-sheet>
  </v-container>
</template>

<script>
import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
  shipmentColorStatus,
  perseRelativeTime,
} from '@/helper/commonHelpers';
import axios from 'axios';
import dayjs from 'dayjs';
import FilterList from './FilterList.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  name: 'ListOrders',
  components: {
    FilterList,
  },
  created() {
    source = CancelToken.source();
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
  data() {
    return {
      items: [],
      headers: [
        {
          text: this.$_strings.dashboard.orderNo,
          value: 'cargoTitle',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '120px',
        },
        {
          text: this.$_strings.dashboard.vehicle,
          value: 'transportName',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '120px',
        },
        {
          text: this.$_strings.dashboard.vehicleNumber,
          value: 'companyTransportNo',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '140px',
        },
        {
          text: this.$_strings.dashboard.driver,
          value: 'driver',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '120px',
        },
        {
          text: this.$_strings.dashboard.lastLocation,
          value: 'longitude',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '150px',
        },
        {
          text: this.$_strings.dashboard.status,
          value: 'latestStatus',
          align: 'center',
          class: 'white--text primary text-capitalize',
          width: '120px',
          sortable: false,
        },
      ],
      expanded: [],
      filters: {
        fromDate: this.$route.query.fromDate || dayjs().subtract('7', 'days').format('YYYY-MM-DD'),
        toDate: this.$route.query.toDate || dayjs().format('YYYY-MM-DD'),
        originLocationId: +this.$route.query.originLocationId || '',
        destinationLocationId: +this.$route.query.destinationLocationId || '',
        companyTransportNo: this.$route.query.companyTransportNo || '',
        latestStatus: this.$route.query.latestStatus || '',
        cargoTitle: this.$route.query.latestStatus || '',
      },
      pagination: defaultPagination(),
      totalItems: 0,
      isLoading: false,
      isErrorGetList: false,
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchOrders();
      },
      deep: true,
    },
  },
  methods: {
    dayjs,
    shipmentColorStatus,
    perseRelativeTime,
    getStatus(status) {
      switch (status) {
        case 'Pesanan Dibuat':
        case 'Menunggu Konfirmasi':
        case 'Menunggu Konfirmasi Transporter':
        case 'Menunggu Konfirmasi Driver':
          return 'WAITING';
        case 'Terkonfirmasi':
        case 'Di Lokasi Penjemputan':
        case 'Di Lokasi Tujuan':
        case 'Pengiriman':
        case 'Penjemputan':
        case 'BAP - Konfirmasi Shipper':
        case 'BA Penerimaan - Ganti Lokasi Tujuan':
        case 'BAP - Konfirmasi':
        case 'Sampai Pelabuhan':
        case 'Muat Barang':
        case 'Menunggu Muat Barang':
        case 'Dalam Penyebrangan':
        case 'Bongkar Muatan':
        case 'Dalam Penerbangan':
        case 'BASP - Konfirmasi Shipper':
        case 'Dalam Proses':
          return 'ON_PROCESS';
        default:
          return 'FINISH';
      }
    },
    showLoading(boolean) {
      if (this.items.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    async searchOrder(status, search) {
      try {
        this.$root.$loading.show();
        const { fromDate, toDate } = this.filters;
        const res = await this.$_services.order.orderList({
          page: 0,
          size: 1,
          pickupDateFrom: fromDate,
          pickupDateTo: toDate,
          sort: 'latestStatus,desc',
        }, status, search, source);
        return res;
      } finally {
        this.$root.$loading.hide();
      }
    },
    async rowClicked(data) {
      const {
        cargoTitle,
        id,
        latestStatus,
      } = data;
      const { fromDate, toDate } = this.filters;
      if (latestStatus && latestStatus.toLowerCase() === 'waktu habis') return;
      this.$router.push({
        name: 'order-detail',
        params: {
          id,
        },
        query: {
          status: this.getStatus(latestStatus),
          cargoTitle,
          pickupDateFrom: dayjs(fromDate).format('YYYY-MM-DD'),
          pickupDateTo: dayjs(toDate).format('YYYY-MM-DD'),
        },
      });
    },
    async fetchOrders() {
      this.isErrorGetList = false;
      this.showLoading(true);
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: handleSortBy({ sortBy, sortDesc }),
        ...this.filters,
      };
      try {
        const res = await this.$_services.dashboard.getDashboardList(skipEmptyStringObject(filters), source);
        let contents = [];
        if (res.data?.contents) {
          contents = res.data.contents.map((i, index) => ({
            ...i,
            type: 'Order',
            subOrderLoading: false,
            index,
          }));
        }
        this.items = contents;
        this.totalItems = res.data?.totalData;
      } catch {
        this.isErrorGetList = true;
      } finally {
        this.showLoading(false);
      }
    },
  },
};
</script>
