<template>
  <v-container fluid>
    shipments
  </v-container>
</template>

<script>
export default {

};
</script>

<style>

</style>
