<template>
  <v-container fluid>
    <dashboard v-if="menuFunctions.VIEW_DASHBOARD"/>
    <dont-have-permission-page v-else/>
  </v-container>
</template>

<script>
import Dashboard from '@/components/Dashboard';

export default {
  name: 'DashboardPage',
  components: {
    Dashboard,
  },
};
</script>
