<template>
  <v-container fluid>
    <div
      v-if="isLoadingShipmentSummary"
      style="height:120px"
      class="d-flex align-center"
    >
      <v-progress-linear
        indeterminate
        color="cyan"
      ></v-progress-linear>
    </div>
    <common-error
      v-else-if="isErrorGetShipmentSummary"
      @fetchData="getShipmentSummary"
    />
    <v-row v-else justify="space-around">
      <v-col cols="auto">
        <summary-item
          status="new"
          :count="shipmentSummary.assigned"
          :href="newCount()"
        />
      </v-col>
      <v-col cols="auto">
        <summary-item
          status="problem"
          :count="shipmentSummary.accident"
          href="/main/masalah/list/waiting"
        />
      </v-col>
      <v-col cols="auto">
        <summary-item
          status="pickup"
          :count="shipmentSummary.pickup"
          :href="pickupCount()"
        />
      </v-col>
      <v-col cols="auto">
        <summary-item
          status="delivery"
          :count="shipmentSummary.delivery"
          :href="deliveryCount()"
        />
      </v-col>
      <v-col cols="auto">
        <summary-item
          status="complete"
          :count="shipmentSummary.completed && (shipmentSummary.completed > 999 ? '999+' : shipmentSummary.completed)"
          href="/main/pesanan/list/completed"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <list-orders />
    </v-row>
  </v-container>
</template>

<script>
import SummaryItem from './SummaryItem.vue';
import ListOrders from './ListOrders.vue';

export default {
  name: 'DashboardPage',
  components: {
    SummaryItem,
    ListOrders,
  },
  data() {
    return {
      isLoadingShipmentSummary: false,
      isErrorGetShipmentSummary: false,
      shipmentSummary: {
        completed: '',
        confirm: '',
        created: '',
        delivery: '',
        pickup: '',
      },
    };
  },
  created() {
    this.getShipmentSummary();
  },
  methods: {
    newCount() {
      const filterStatus = 'MENUNGGU_KONFIRMASI_DRIVER,MENUNGGU_KONFIRMASI_TRANSPORTER,DRIVER_WAKTU_HABIS';
      const href = `/main/pesanan/list/waiting?latestStatus=${filterStatus}`;
      return href;
    },
    pickupCount() {
      const filterStatus = 'PENJEMPUTAN,DI_LOKASI_PENJEMPUTAN';
      const href = `/main/pesanan/list/process?latestStatus=${filterStatus}`;
      return href;
    },
    deliveryCount() {
      const filterStatus = 'PENGIRIMAN,DI_LOKASI_TUJUAN,BA_PENERIMAAN_KONFIRMASI_SHIPPER,MENUNGGU_MUAT_BARANG,MUAT_BARANG,MENYEBRANG_PENGIRIMAN,DALAM_PENERBANGAN,DALAM_PENYEBRANGAN,SAMPAI_BANDARA,SAMPAI_PELABUHAN,BONGKAR_MUATAN';
      const href = `/main/pesanan/list/process?latestStatus=${filterStatus}`;
      return href;
    },
    async getShipmentSummary() {
      try {
        this.isLoadingShipmentSummary = true;
        this.isErrorGetShipmentSummary = false;
        const res = await this.$_services.dashboard.getShipmentSummary();
        this.shipmentSummary = res.data;
      } catch {
        this.isErrorGetShipmentSummary = false;
      } finally {
        this.isLoadingShipmentSummary = false;
      }
    },
  },
};
</script>
